import React from 'react'
import styled from 'styled-components'
import variables from '../../styles/variables'

const Svg = styled.svg`
  transform: rotate(0deg);
  margin: 0 auto;
  display: block;
  ${({ isArrowTopLeft }) => isArrowTopLeft && 'transform: rotate(5deg); position: absolute; top: -36px;left: 179px;'};
  ${({ isArrowDownRight }) => isArrowDownRight && 'transform: matrix(-0.26, -0.97, -0.97, 0.26, 0, 0)'};
  ${({ isArrowDownLeft }) => isArrowDownLeft && 'transform: rotate(-75deg)'};
  ${({ isArrowTopRight }) =>
    isArrowTopRight && 'transform: rotate(122.67deg);margin: 0;position: absolute; top: 28px; left: -36px'}
`
export function NounArrow({ isArrowTopLeft, isArrowDownRight, isArrowDownLeft, isArrowTopRight }) {
  return (
    <Svg
      width='188'
      height='188'
      viewBox='0 0 188 188'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      isArrowTopLeft={isArrowTopLeft}
      isArrowDownRight={isArrowDownRight}
      isArrowDownLeft={isArrowDownLeft}
      isArrowTopRight={isArrowTopRight}
    >
      <path
        d='M167.272 119.756C167.257 119.747 166.462 119.277 164.858 118.421L165.919 116.43C167.601 117.327 168.425 117.818 168.425 117.818L167.272 119.756Z'
        fill='black'
      />
      <path
        d='M159.924 115.91C158.473 115.199 156.8 114.402 154.904 113.529L155.848 111.48C157.762 112.36 159.448 113.165 160.915 113.881L159.924 115.91ZM149.83 111.26C148.236 110.565 146.533 109.837 144.713 109.081L145.58 106.998C147.411 107.76 149.129 108.493 150.733 109.194L149.83 111.26ZM139.562 106.981C137.909 106.323 136.182 105.65 134.377 104.959L135.181 102.851C136.997 103.545 138.736 104.223 140.395 104.887L139.562 106.981ZM129.165 103.005C127.479 102.385 125.734 101.755 123.928 101.116L124.68 98.9895C126.494 99.6306 128.248 100.264 129.944 100.888L129.165 103.005ZM118.669 99.2885C116.957 98.7038 115.195 98.1135 113.388 97.5194L114.093 95.3762C115.908 95.974 117.675 96.5662 119.395 97.1528L118.669 99.2885ZM108.089 95.8048C106.357 95.254 104.586 94.7013 102.772 94.1448L103.434 91.9884C105.256 92.5468 107.034 93.1033 108.773 93.656L108.089 95.8048ZM97.4385 92.5336C95.6901 92.0147 93.9079 91.494 92.0899 90.9732L92.7141 88.8056C94.5358 89.3282 96.3256 89.8509 98.0796 90.3716L97.4385 92.5336ZM86.7319 89.4579C84.9685 88.9691 83.1787 88.4803 81.357 87.9915L81.9417 85.8126C83.7728 86.3033 85.5701 86.794 87.3354 87.2847L86.7319 89.4579ZM75.9689 86.5684C74.1961 86.1078 72.3969 85.6491 70.5696 85.1903L71.1204 83.002C72.9515 83.4626 74.7582 83.9251 76.5348 84.3857L75.9689 86.5684ZM65.1608 83.8537C63.3786 83.4213 61.5738 82.9889 59.7426 82.5583L60.2559 80.3625C62.0945 80.793 63.905 81.2273 65.6928 81.6616L65.1608 83.8537ZM54.3132 81.3006C52.5234 80.8945 50.7092 80.4866 48.8744 80.0843L49.3594 77.8809C51.1999 78.287 53.0179 78.6931 54.8114 79.101L54.3132 81.3006ZM43.4261 78.9036C41.6288 78.5201 39.8109 78.1403 37.9722 77.7606L38.4272 75.5516C40.2696 75.9313 42.0951 76.313 43.8961 76.6965L43.4261 78.9036ZM32.509 76.6551C30.7042 76.2979 28.8824 75.9407 27.04 75.5854L27.4668 73.3689C29.313 73.7242 31.1422 74.0814 32.9508 74.4424L32.509 76.6551Z'
        fill='black'
      />
      <path d='M21.9329 72.3264L19.1631 71.817L18.755 74.0357L21.5247 74.5452L21.9329 72.3264Z' fill='black' />
      <path
        d='M26.1393 81.6992L16.2393 71.7991L29.5703 68.1255L30.1701 70.3007L20.5745 72.944L27.7336 80.103L26.1393 81.6992Z'
        fill='black'
      />
    </Svg>
  )
}
